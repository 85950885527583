<template>
    <el-dialog v-model="dialogVisible" title="详情" width="50%">

        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange" @current-change="currentChange">
        </avue-crud>

    </el-dialog>
</template>
<script setup>
import { ref, defineExpose } from 'vue'
import { detailsWork } from '@/const/crud/record/customerWallet'
import mixins from '@/views/mixins/page'

let pageObj = mixins(['getNftiteminfo'])
const { page, listLoading, sizeChange, currentChange, getList,updateOtherParm } = pageObj
const dialogVisible = ref(false)

const tableOption = ref({}); tableOption.value = detailsWork
const open = (obj) => {
    dialogVisible.value = true
    updateOtherParm(obj)
    getList(1)
}
defineExpose({ open })
</script>

<style lang="scss" scoped></style>